import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { EditDate, EditSelect, ICommand, Input, RelayCommand, ThemedLoader } from "Application";
import { ReportListViewModel } from "./ReportListViewModel";
import { H2, H3 } from "Components/Primitives/TextElements/TextElements";
import { HRDashedGreyNoBottom } from "Components/HorizontalRules";
import { FilteringSectionBox, ReportListWrapper, ReportSection, ReportWrapper } from "./ReportListView.styles";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { MultiEditSelect } from "Components/Primitives/Selects/MultiEditSelect";
import { KeyValuePair } from "@shoothill/core";
import spyScope from "Assets/Icons/magnifyingGlass.svg";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { ProductTypeSelectWrapper } from "Components/Wrappers/ProjectTypeSelectWrapper";
import { DatePickerBox } from "Components/Primitives/DatePickerBox";
export const ReportListView: React.FC = observer(() => {
    const [viewModel] = useState(() => ReportListViewModel.Instance);
    useEffect(() => {
        let promise = viewModel.getUnmatchedSageNotFoundCount();
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    const handleProjectCustomerSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Download) {
            let promise = viewModel.downloadProjectAndCustomerCSV();
        }
    });

    const handleProjectMasterQuotesSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Download) {
            let promise = viewModel.downloadProjectMasterAndProjectQuotesCSV();
        }
    });

    const handleDocumentCategoriesSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Download) {
            let promise = viewModel.downloadProjectDocumentCategoriesCSV();
        }
    });

    const handleProjectSageContractorSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Download) {
            let promise = viewModel.downloadProjectSageContractorCSV();
        }
    });

    const handleProjectCommunicationSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Download) {
            let promise = viewModel.downloadProjectCommunicationsCSV();
        }
    });

    const handleProjectComplaintsSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Download) {
            let promise = viewModel.downloadProjectComplaintsCSV();
        }
    });

    const handleCategorySubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Download) {
            let promise = viewModel.downloadCustomerContactsCSV();
        }
    });

    const handleContractorPlygeneSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Download) {
            let promise = viewModel.downloadCustomerPlygeneCSV();
        }
    });

    const handleContractorCoatingsSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Download) {
            let promise = viewModel.downloadCustomerCoatingCSV();
        }
    });

    const handleSageNotFoundSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Download) {
            let promise = viewModel.downloadSageNotFoundCSV();
        } else if (value === subHeadingButton.ShowData) {
            if (viewModel.getCanResetTable === true) {
                let promise = viewModel.SageNotFoundReset();
            }
        }
    });

    const handleProjectJFFButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Download) {
            let promise = viewModel.downloadProjectJFFCSV();
        }
    });

    const updateTypeFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateTypeFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
    });

    const typeSelectAll = (): void => {
        viewModel.typeSelectAll();
    };

    const typeSelectNone = (): void => {
        viewModel.typeSelectNone();
    };

    // Project Types

    const updateProductFiltering: ICommand = new RelayCommand((values: KeyValuePairExtended[]) => {
        let children: string[] = viewModel.products.filter((item) => item.class === "child").map((child) => child.key);
        viewModel.updateProductFiltering(values.filter((item) => children.includes(item.key)).map((item) => item.key));
    });

    const formatOptionLabel = ({ value, label, cls }: any) => <p className={cls}>{label}</p>;

    const productSelectAll = (): void => {
        viewModel.productSelectAll();
    };

    const productSelectNone = (): void => {
        viewModel.productSelectNone();
    };

    // End Project Types

    // BDM

    const updateBdmFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateBdmFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
    });

    const updateCategory: ICommand = new RelayCommand((value: KeyValuePair) => {
        viewModel.setSelectedCategory(value.key);
    });

    const bdmSelectAll = (): void => {
        viewModel.bdmSelectAll();
    };

    const bdmSelectNone = (): void => {
        viewModel.bdmSelectNone();
    };

    // End BDM

    // Task Type

    const updateTaskTypeFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateTasksTypeFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
    });

    const taskTypeSelectAll = (): void => {
        viewModel.tasksTypeSelectAll();
    };

    const taskTypeSelectNone = (): void => {
        viewModel.tasksTypeSelectNone();
    };

    // Task Type

    return (
        <>
            {viewModel.isProcessing ? (
                <ThemedLoader isOpen={true} spinnerText="Downloading..." delayBeforeOpen={200} />
            ) : (
                <ReportListWrapper>
                    <H2 pb="15px">Reports</H2>
                    <p></p>
                    <HRDashedGreyNoBottom />
                    <ReportWrapper>
                        <SubHeaderView relativePosition={true} buttonClick={handleProjectCustomerSubHeadingButtonClicks} downloadButtonText="Download Project & Customer CSV" />
                        <ReportSection>
                            <H3 pb="15px" mt="15px">
                                Project & Customer
                            </H3>

                            <FilteringSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr"}>
                                <Input
                                    command={viewModel.projectAndCustomerSearchKeywordCommand}
                                    placeholder="Start typing to search by"
                                    prefix={<img src={spyScope} style={{ cursor: "pointer" }} />}
                                    displayName=""
                                    noHeader={true}
                                    value={() => viewModel.projectAndCustomerSearchValue}
                                />
                                <ProductTypeSelectWrapper>
                                    <MultiEditSelect
                                        command={updateProductFiltering}
                                        displayName="Product"
                                        options={viewModel.getProducts}
                                        value={() => viewModel.getProductFilter}
                                        showSelectAllNone={true}
                                        selectAll={productSelectAll}
                                        selectNone={productSelectNone}
                                        formatOptionLabel={formatOptionLabel}
                                        isOptionDisabled={(option) => option.class == "parent"}
                                        hasParentChildren={true}
                                        isDisabled={viewModel.isProcessing}
                                    />
                                </ProductTypeSelectWrapper>
                                <MultiEditSelect
                                    command={updateBdmFiltering}
                                    displayName="BDM"
                                    options={viewModel.getBdms}
                                    value={() => viewModel.getBdmFilter}
                                    showSelectAllNone={true}
                                    selectAll={bdmSelectAll}
                                    selectNone={bdmSelectNone}
                                    isDisabled={viewModel.isProcessing}
                                />
                                <DatePickerBox>
                                    <EditDate
                                        command={viewModel.setTableDateFrom}
                                        value={viewModel.getTableDateFrom}
                                        displayName="Start Date"
                                        style={{ marginRight: "10px" }}
                                        includeTime={false}
                                    />
                                </DatePickerBox>
                                <DatePickerBox>
                                    <EditDate
                                        command={viewModel.setTableDateTo}
                                        value={viewModel.getTableDateTo}
                                        displayName="End Date"
                                        style={{ marginRight: "10px" }}
                                        includeTime={false}
                                    />
                                </DatePickerBox>
                            </FilteringSectionBox>
                        </ReportSection>
                    </ReportWrapper>
                    <HRDashedGreyNoBottom />
                    <ReportWrapper>
                        <SubHeaderView relativePosition={true} buttonClick={handleProjectJFFButtonClicks} downloadButtonText="Download Project JFF CSV" />
                        <ReportSection>
                            <H3 pb="15px" mt="15px">
                                Project JFF
                            </H3>

                            <FilteringSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr"}>
                                <EditSelect
                                    command={updateCategory}
                                    displayName="Category"
                                    options={viewModel.getCategories}
                                    value={() => viewModel.getCatagorySelected}
                                    isDisabled={viewModel.isProcessing}
                                />
                                <DatePickerBox>
                                    <EditDate
                                        command={viewModel.setJFFDateFrom}
                                        value={viewModel.getJFFDateFrom}
                                        displayName="Start Date"
                                        style={{ marginRight: "10px" }}
                                        includeTime={false}
                                    />
                                </DatePickerBox>
                                <DatePickerBox>
                                    <EditDate
                                        command={viewModel.setJFFDateTo}
                                        value={viewModel.getJFFDateTo}
                                        displayName="End Date"
                                        style={{ marginRight: "10px" }}
                                        includeTime={false}
                                    />
                                </DatePickerBox>
                            </FilteringSectionBox>
                        </ReportSection>
                    </ReportWrapper>

                    <HRDashedGreyNoBottom />
                    <ReportWrapper>
                        <SubHeaderView
                            relativePosition={true}
                            buttonClick={handleProjectMasterQuotesSubHeadingButtonClicks}
                            downloadButtonText="Download Project Master & Project Quote CSV"
                        />
                        <ReportSection>
                            <H3 pb="15px" mt="15px">
                                Project Master & Project Quote
                            </H3>
                        </ReportSection>
                    </ReportWrapper>
                    <HRDashedGreyNoBottom />
                    <ReportWrapper>
                        <SubHeaderView
                            relativePosition={true}
                            buttonClick={handleDocumentCategoriesSubHeadingButtonClicks}
                            downloadButtonText="Download Project Document Categories CSV"
                        />
                        <ReportSection>
                            <H3 pb="15px" mt="15px">
                                Project Document Categories
                            </H3>
                        </ReportSection>
                    </ReportWrapper>
                    <HRDashedGreyNoBottom />
                    <ReportWrapper>
                        <SubHeaderView
                            relativePosition={true}
                            buttonClick={handleProjectSageContractorSubHeadingButtonClicks}
                            downloadButtonText="Download Project Sage Contractor CSV"
                        />
                        <ReportSection>
                            <H3 pb="15px" mt="15px">
                                Project Sage Contractor
                            </H3>
                        </ReportSection>
                    </ReportWrapper>
                    <HRDashedGreyNoBottom />
                    <ReportWrapper>
                        <SubHeaderView
                            relativePosition={true}
                            buttonClick={handleProjectCommunicationSubHeadingButtonClicks}
                            downloadButtonText="Download Project Communications CSV"
                        />
                        <ReportSection>
                            <H3 pb="15px" mt="15px">
                                Project Communications
                            </H3>
                        </ReportSection>
                    </ReportWrapper>
                    <HRDashedGreyNoBottom />
                    <ReportWrapper>
                        <SubHeaderView relativePosition={true} buttonClick={handleProjectComplaintsSubHeadingButtonClicks} downloadButtonText="Download Project Complaints CSV" />
                        <ReportSection>
                            <H3 pb="15px" mt="15px">
                                Project Complaints
                            </H3>
                        </ReportSection>
                    </ReportWrapper>
                    <HRDashedGreyNoBottom />
                    <ReportWrapper>
                        <SubHeaderView relativePosition={true} buttonClick={handleCategorySubHeadingButtonClicks} downloadButtonText="Download Customer Contacts CSV" />
                        <ReportSection>
                            <H3 pb="15px" mt="15px">
                                Customer Contacts
                            </H3>
                        </ReportSection>
                        <FilteringSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr"}>
                            <Input
                                command={viewModel.customerContactsSearchKeywordCommand}
                                placeholder="Start typing to search by"
                                prefix={<img src={spyScope} style={{ cursor: "pointer" }} />}
                                displayName=""
                                noHeader={true}
                                value={() => viewModel.customerContactsSearchValue}
                            />
                            <MultiEditSelect
                                command={updateTypeFiltering}
                                displayName="Customer Type"
                                options={viewModel.getCustomerTypesForDropdown}
                                value={() => viewModel.getTypeFilter}
                                showSelectAllNone={true}
                                selectAll={typeSelectAll}
                                selectNone={typeSelectNone}
                                isDisabled={viewModel.isProcessing}
                            />
                        </FilteringSectionBox>
                    </ReportWrapper>
                    <HRDashedGreyNoBottom />
                    <ReportWrapper>
                        <SubHeaderView relativePosition={true} buttonClick={handleContractorPlygeneSubHeadingButtonClicks} downloadButtonText="Download Contractor Plygene CSV" />
                        <ReportSection>
                            <H3 pb="15px" mt="15px">
                                Contractor Plygene
                            </H3>
                        </ReportSection>
                    </ReportWrapper>
                    <HRDashedGreyNoBottom />
                    <ReportWrapper>
                        <SubHeaderView relativePosition={true} buttonClick={handleContractorCoatingsSubHeadingButtonClicks} downloadButtonText="Download Contractor Coatings CSV" />
                        <ReportSection>
                            <H3 pb="15px" mt="15px">
                                Contractor Coatings
                            </H3>
                        </ReportSection>
                    </ReportWrapper>
{/*                     <HRDashedGreyNoBottom />
                    <ReportWrapper>
                        <SubHeaderView
                            relativePosition={true}
                            buttonClick={handleSageNotFoundSubHeadingButtonClicks}
                            downloadButtonText="Download Sage Not Found CSV"
                            showDataText="Reset Database Table"
                        />
                        <ReportSection>
                            <H3 pb="15px" mt="15px">
                                Sage Not Found
                            </H3>
                            <p>Count of unmatched records: {viewModel.getUnmatchedSageRecordCount}</p>
                            <p>Countdown Timer (s) : {viewModel.getCountdownTimer}</p>
                        </ReportSection>
                    </ReportWrapper> */}
                </ReportListWrapper>
            )}
        </>
    );
});
