import * as Utils from "Utils/Compare";

export interface ChangeLogVersion {
    version: string;
    date: string;
    items: ChangeLogItem[];
}

export interface ChangeLogItem {
    description: string;
}

const createVersion = (version: string, date: string, items: string[]) => {
    return {
        date,
        items: items.map((item) => {
            return { description: item };
        }),
        version,
    };
};

const versions = [
    //createVersion("2.0.11", "2024-12-",[""]),
    createVersion("2.0.10", "2024-12-17",["Reports - Removed Sage Not Found Report"]),
    createVersion("2.0.9", "2024-12-16",[
        "Reports - Sage Not Found - Fixed issue where reset wasn't persisting the deletion",
        "Report - Sage Not Found - Fixed issue with missing order numbers",
        "Report - Customer Contact Report - Added Primary contact field to the report",
    ]),
    createVersion("2.0.8", "2024-12-11", ["Work - Project Quote List - Added Min and Max Net Value filters to the list page",
        "Work - Project Quote List - Used Min and max filters for the CSVde download",
        "Work - Project Quote List - Used Min and max filters when deleting a project quote",
        "Work - Project Quote List - Used Min and max filters when changing the project quote status manually",
        "Work - Master Project ist - Added Min and Max Net Value filters to the list page",
        "Work - Master Project List - Used Min and max filters for the CSV download",
        "Work - Master Project List - Used Min and max filters when deleting a project quote",
        "Directory - Properties - Fixed issue with created date not coming through on the list",
        "Directory - Customer - Master project linked list, added postode to table",
        "Directory - Customer - Project Quote linked list, added postode to table",
        "Work - Master Project & Project Quote - Fixes for Plygene in calculations",
    ]),
    createVersion("2.0.7", "2024-12-09", ["Directory - Customer - Added Agricultural customer type",
        "Work - Multisite Project - Edit - Fixed issue with page reload on searching within the dropdowns",
        "Work - Project Quote - Reworked Open / Won / Lost / Net to use Max value not sum",
        "Work - Master Project - Reworked Open / Won / Lost / Net to use sum of max Lining and Coating Types only",
    ]),
    createVersion("2.0.6", "2024-12-03", ["AfterSales - Complaint - Fixed issue where a V1 complaint could not be opened since the parent project is in a Prospect state",
        "General - Address - Relaxed some rules to allow user to enter location and postcode data for non UK addresses",
        "AfterSales - Complaint - Date filtering change to fix resolved complaints not showing",
        "General - Tables - Changes to tables and widths",
        "Directory - Customer - Implemented change customer types for those customers without monatary value assigned",
        "Aftersales - Complaint - Reworked the algortithm to extract postcode from address string on the list table",
        "Reports - Customer Contact - Added Customer Email to the report",
        "General - Address - Removed need for a postcode, if the address country is not United Kingdom",
        "General - Address - Refine location, reworked the map to move when the latitude and longitude is typed in",
        "Directory - Customer - Changed Facility Manager to Property Manager",
        "Directory - Customer - Added new Distributor type",
    ]),
    createVersion("2.0.5", "2024-11-13", [
        "Reports - JFF - Fixed UTF8 issue with the Band column and added Lost Value into net value, when appropriate",
        "Work - Master Project - Add/Edit master project page's property section to add a Property",
        "Work - Master Project - Fixed an issue where the map pin could not be moved when adding a property in the modal",
        "Aftersales - Complaints - Fixed an issue where the map pin could not be moved when adding a customer in the modal",
        "Aftersales - Complaints - Filters - Works similar to V1, in that for unresolved the dates do not apply.  PDF also changed to work in a similar way",
        "General - Document uploader. Fixed issue where .msg files are not natively supported in the underlying framework.",
        "General - Document uploader. Fixed issue where file names have problematic non alpha numeric characters",
        "View Document Modal - Fixed issue that caused loading times to be longer than expected ",
        "General - Document uploader. Fixed issue where on file upload, all files come back with current date",
        "Report - JFF - Set Project Quote 'Is specifier generated' based on it's master project",
    ]),
    createVersion("2.0.4", "2024-10-17", [
        "Reports - Added JFF Report",
        "Directory - Customer - Details - Changed note to split out on line breaks",
        "Directory - Contact - Added address and postcode to the CSV download",
        "Property - Added confirmation pop up Modal on Property update who's linked with any master project",
    ]),
    createVersion("2.0.3", "2024-10-15", [
        "Work - Project Quote - Added an Estimate value field and imported from V1",
        "Work - Lists changed to work off date descending",
        "Work - Project Quote - Re-added Surveyed by TSM from V1",
        "Directory - Customer - Added checkbox to search to only search postcodes",
        "General - Allowed File types expanded to cover all types used in V1",
        "Directory - Customer - County made a mandatory field",
        "Work - Project Quote - Add Complaint - Implementing prepopulating the order value and site address from the project quote",
        "Reports - Customer Contact - Added Customer address and Contact CPD training details",
        "Reports - Customer Contact - Split postcode out from address",
        "TSM - Changes for Modal and styling fixes on EditDate",
        "TSM - Rewired so Surveyed and Site Inspection works on the Project Quote selected, not its Property",
        "Directory - Properties - Removed Site Inspection and Surveyed By TSM options",
        "Work - Master Project - Removed links to Property Site Inspection and Surveyed by TSM and removed its own Site Inspection",
        "Work - Project Quote - Search string now additionalyy searches for order numbers at a time cost",
        "V1 Importer - Reworked for all the changes to property, Master Project and Project Quote",
        "TSM - Request Site Visit - When the time changes, can the (currently static) label be updated",
        "TSM - Request Site Visit - default the time to 09:00 am",
        "Fixed the styling of the FilteringTopSectionGenericView for the search bars",
        "Work - Project Quote - Edit - Added Master Number into list of search criteria for the dropdown",
        "Work - Project Quote - Complaint - Add - Order value now uses net amount instead of gross amount",
    ]),
    createVersion("2.0.2", "2024-10-03", [
        "Work - Master Project - Added Is Specifier Generated, Site Inspection, Spcified Through NBS, Segro and Lead Source fields",
        "Work - Master Project - List - Added postcode column",
        "Work - Project Quote - Added Guarantee, Site Inspection and Condition Report fields ",
        "Directory - Customers - Added in-active state on by default in the list filter",
        "Directory - Customers - Contacts - Inital sort changed from last added to First name",
        "Aftersales - Complaints - Initial sort changed to refernece number ascending.",
        "Aftersales - Complaints - View - Added Project number link to details tab.",
    ]),
    createVersion("2.0.1", "2024-10-01", ["Directory - Customers - Added a generic note to the Customer object, separate from notes list."]),
    createVersion("2.0.0", "2024-09-30", ["Version 2 Initial Release"]),

    /*     createVersion("0.0.32", "2024-09-30", [
        "Tasks - Add a popup to set a completion date for tasks",
        "Contact - Added functionality for document download",
        "Add Contact - CPD / Trained Date - Required validation for the Added Date",
        "Master Project - Specifier - Add a contact to a specifier",
        "Contacts - CPD / Training.  Imported V1 specifiers and moved training to CPD versions.",
        "Contatct - Fixed issues with status not taking into account CPD and re-wrote logic for training columns to cope witht the two new types",
        "General - All top level tables, are sticky when they reach the top of the screen as the user scrolls",
        "Tasks = Added completed date to Task CSV",
        "Reports - Fixed missing Project Quote complaints and ensured some reports have the correct columns",
        "Tasks - Add to the task detail popup the source of the information",
        "General - Tables on tabs are sticky when they reach the top of the screen as the user scrolls",
        "Tasks - Task popup Customer / project names are links to their actual page",
        "Tasks - Fixed issues task popup display the previous data before the new data is loaded",
        "Customers - Contacts - Fixed issue with contact not save",
        "Remove status column for the contact list",
        "Files - Download. Fixed issue where the file download failed for non admin",
        "Customer - Linked Projects - Default sorting is now on project creation date descending",
        "Projects Quotes - Added ability to move a Project Quote to a new Master",
        "Reports - Fixed issue for CRM user role as it freeze system and not load anything",
        "Projects Quotes - Applied search and filtered list display in master project dropdown",
    ]), */
    /*     createVersion("0.0.31", "2024-07-17", [
        "Master Project - Customers - Remove Added By column",
        "Master Project - Specifier -  Fixed the issue specifier search issue",
        "Work - Project Quote - List - Added project status change on table",
        "Master Project - Customers -  Added a CustomerType column to the table",
    ]), */
    /* createVersion("0.0.30", "2024-06-28", [
        "Aftersales - Complaints - List - Documents Tab - Fixed the issue Uploaded the incorrect file",
        "Aftersales - Complaint - List - Documents Tab -  Added by columns wasn't displaying",
        "Aftersales - Complaint - List - Fixed the issue Download the pdf not working",
        "Tasks - List - Tasks for deleted projects are shown as well in the task list",
        "Admin - Users/TSMs - List - 'created' columns have been changed to 'Created date'",
        "Admin - Users/TSMs - List - Name search not working",
        "Admin - Users - List - Name filter is not functioning properly",
        "Admin - TSMs/BDMs - List - Name,Created by filter is not functioning properly",
        "Condition Report - List -  Enhance the size of the PDF preview",
        "Master Project - Merge Occupier and specifier tabs",
        "Master Project - Complaints - Project Quote related data display",
        "Master Project - Detail - added property tab details",
        "Date on the download CSV shows a day ahead of the selected date",
        "Tasks - List - display of the downloaded CSV record is incorrect",
        "Contacts - List -  keyword search for contact names does not work in the downloaded CSV file",
        "Aftersales - Complaint Details - remove order value edit box",
    ]),
    createVersion("0.0.29", "2024-06-14", [
        "Customers - Big Change - fix for invalid phone numbers with '/' in them",
        "Customers - Big Change - fix specifiers / facility managers, now standard DB, to stop full name being put into Big Change Surname field, which will ignore the firstname field",
        "Customers - Phone Numbers - Added additional phone number field, since a lot of V1 phone numbers have two numbers in, names, extensions, email addresses, etc",
        "Customer - Address, if valid postcode and invalid latitude and longitude, then get and set the latitude and longitude on save",
        "Importer - Phone Numbers - Fixed as many phone numbers as I could, since Big Change only allows 16 characters, split into phone and additional phone number fields",
        "Customer & Property - Address, removed Country text box and replaced with a dropdown list of countries from Sage",
        "Importer - Customer and Property, try to work out the country from the address in V1, given there are some international addresses.  This is needed since Big change needs phone numbers in the correct country format.",
        "Customer & Property - Style pass on customer and property pages",
        "Property - Address, if valid postcode and invalid latitude and longitude, then get and set the latitude and longitude on save",
        "Property - Details - Added pin to map, showing where property is located",
        "Condition Report - List - Added Editor filter to the list page",
        "User - Updated user phone number in V1 and imported in V2",
        "Master Project - Renamed PRO to Occupier",
        "Master Project -Complaints- Fixed the issue Missing Reporting company",
        "Project Quote- Complaints- Fixed the issue Missing Reporting company",
        "Master Project - Communication - Fixed the issue note documents not saving",
        "Contacts - List - Fixed the issue contact search not working",
        "Complaint - categories dropdown parent and child selection fixed",
        "Master Project - Property - details tab header section fixed",
        "Project Quote - Add default the BDM to be the same as the Master Project",
        "Linked project quotes - Filter by status dropdown view fixed",
        "View contact popup model - additional phone number wasn't displaying",
        "Master Project - Specifier - Added line address 1, city, postcode with the specifier name for identification",
        "Contact - Added validation for either Phone Number Or Email needs",
        "Communication / Note Tabs - Added new colum 'Docs' to shows total document count",
        "Master Project - Property Tab - Added marker to the map to show where property is located",
        "Customer - Display - If there's no latitude nor longitude, hide the goto google option - expanded system wide",
        "Customer/Project Quote/Master Project/Multi site projects - Note Tab - Created date colum shows update date value and heading for date changed to created date",
        "Addresses - Control - Added the ability to move the location for the address, via a map, auto calculate the latitude and longitude if the postcode is valid",
        "Add Contact popup - shows validation wrongly on phone number and email on close and reopening of the form",
        "General Address - Added ability to edit the latitude and longitude, for Irish and other countries, where we don't have a postcode api",
        "Tasks - Description column - provided limit for long text",
        "Contact list - Plygene CPD,Coatings CPD column values was showing blank",
        "Contact list - Add flag for primary contacts",
        "Tasks - Fixed styling for Tasks table",
        "Complaint - Details - Company who reported are wasn't displaying",
        "Customer - Linked Multi site - Fixed issue the record was not being displayed",
        "Master project - Property Details - Roof substrate value not updating after save",
        "Multi site projects - Properties - Properties list record wrong display",
        "Multi site projects - download csv in No.of master colum count wrong display",
        "Multi site projects - Add master project -  master projects added display wrong",
        "Multi site projects - Properties - roof substrate value is incorrect displayed",
        "Communication - When a note is deleted in Docs,data created by Colum does not appear",
        "Master projects - Add occupiers/Add specifiers - information popup the status has not been updated",
        "Aftersales - Complaint List - Postcode wasn't displaying",
        "Master projects - Complaints - Updated few field names as per displayed on web for Complaints pdf",
        "Master projects - Details - Delivery date showing wrong (showing current date instead of actual date)",
        "Master projects - List - The timescale dropdown has the option to choose the Today date, yet the data being shown is incorrect.",
        "Projects Quotes - Add contractor - The information popup for Tss Number and Eori was not being shown.",
        "Projects Quotes - list - project name search feature is currently not functioning properly.",
        "Master projects - list - Delete the icon that is not being displayed.",
        "Master projects - Details - New project quote - Certain options are missing in the BDM list",
        "TSM - list - change the style of the dropdown to filter by status",
        "TSM - list - project name,contractors search not working",
        "TSM - list - DOCS - file of the wrong type has been uploaded",
    ]),
    createVersion("0.0.28", "2024-05-17", [
        "Condition Report - Added Editor column into the main Condition report table, as to who uploaded the json file.",
        "Sage - Soft deleting no matches so we can reprocess later when the data is available",
        "Add document - 'Added By' user name not displaying",
        "View document - Fixed the issue Type error on view document",
        "Customer - Add New customer - Fixed the issue Failed to store customer",
        "Sage Importer - Re wrote the importer to re-process any non-matched data and to re-process the data that has been soft deleted",
        "Work - Master projects - View document - Type error on view document",
        "Work - Master projects - Add document - 'Added By' user name not displaying",
        "Work - Project Quote - View document - Type error on view document",
        "Work - Project Quote - Add document - 'Added By' user name not displaying",
        "Work - Project Quote - Fixed the issue On filter CSV downloaded with wrong data",
        "Work - Multi site projects - Edit screen and details page shows deleted master project records",
        "Work - Multi site projects - List - The count of master projects includes deleted records as well",
        "Work - Project Quote - Added Overall Status to the CSV",
        "Work - Project Quote - List - Added sorting to the Delivery Date column",
        "Work - Project Quote - Added statuses 'Condition Report Conversion' and 'Condition Report Check'",
        "Work - Master projects - Property Details - Fixed the issue Master projects linked to this property table click not proper work",
        "Directory - Customers - Fixed the issue edit customer return wrong",
        "Work - Multi site projects - Fixed the issue edit multi site projects return wrong",
        "Work - Project Quote - CSV delivery date added and formatted",
        "Work - Project Quote - List & CSV optimizations",
        "Work - Project Quote - List & download CSV does not work",
        "Directory - Contacts -  Fixed the issue Add/Edit  document not add.",
        "Directory - Customers - Fixed the issue occupied type customer not saving",
        "Directory - Customers - Fixed the issue contractor, specified, Facility manager related data were not saving",
        "Directory - Customers - Add document 'Added by' not display",
        "Directory - Customers -  Fixed the issue customer communication in delete note document not displaying",
        "Directory - Contact - Add new Contact 'Add contact & continue' button click was adding multiple record",
        "Contacts - add contact - radio button styling",
        "Work -  Project Quote -  Fixed the issue download CSV throws error",
        "Directory - Customers - Fixed the issue Edit contact new document upload click on 'Save contact & exit' button directly, reopen the edit modal and document still shows",
        "Directory - Properties - Fixed the issue Multi-site projects linked, Master projects linked, Project quotes linked tab count issue",
        "Directory - Customers - Fixed the issue Multi-site projects linked, Master projects linked, Project quotes linked tab count issue",
        "Directory - Properties - Clicking on the 'Edit property details' button - Clicking the 'Back to' button - Going back to the wrong page",
        "Work - Master Project - Display the record for deleting property details in Master projects associated with this property",
        "Reports - Customer Contacts - Added in Customer status as per V1 changes",
        "Work - Master Project List - Added dates to make consistent with other two work lists",
        "TSM - List - Fixed the issue the filter for Surveyed and Site Inspection is not functioning",
        "TSM - List - Fixed the issue the net value of the Project Quote and TSM List are not the same",
        "TSM - List - Fixed the issue DOC count is displayed incorrectly",
        "TSM - Fixed the issue Clicking on a row in the TSM list takes you back to TSM, but it goes to the wrong place",
        "Admin - Users - Fixed issue the email address has already but added to the user account",
        "Work - Lists - Added spinner and disabled controls, when site is processing",
        "Dashboard - Tasks - Added spinner and disabled controls, when site is processing",
        "TSM - List - Added spinner and disabled controls, when site is processing",
        "Aftersales - Complaint - Added spinner and disabled controls, when site is processing",
        "Buttons - Added disabled state to the blue buttons where appropriate",
        "Admin - Lists - Added spinner and disabled controls, when site is processing",
        "Directory - Lists - Added spinner and disabled controls, when site is processing",
        "Work - Master projects - Fixed the issue return wrong",
        "Importer - Fixed the issue the Master project number incorrect and failure to save if no financial data due to no project quotes.",
        "Add Contact popup - shows validation wrongly on Select customer type close and reopening of the form",
        "New property - select occupier Information popup form - Occupier notes and Linked projects data not displaying",
        "Make occupier popup modal same throughout and add Plygene CPD & Coating CPD options on the modal",
        "Directory - Customers - Edit customer - Latitude and Longitude field value not updating after save",
    ]),
    createVersion("0.0.27", "2024-05-03", [
        "Sage Importer - Changes after testing UAT to clean up no match data",
        "Condition Report - Table of Contents - Reduced max number of lines before the page breaks",
        "Condition Report - Table of Contents - Fixed issue where if you add images to H1 and H2 etc tags, they end up in the table of contents",
        "Aftersales- Complaint -  Fixed the issue with Download the pdf Corrective Actions, Preventative Actions, and Outstanding Tasks data are not displayed",
        "Aftersales - Reports -   Fixed the issue with Download the pdf Corrective Actions, Preventative Actions, and Outstanding Tasks data are not displayed",
        "Directory - Customers  - Display In-active data in the customer list during initial load time",
        "Aftersales - Complaint View - Fixed the issue with order Number not displaying properly on edit complaint page",
        "Dashboard - Tasks - Fixed the sorting of source column in the task list.",
        "Aftersales - Complaint View - Fixed the issue with Task total count not displaying correctly",
        "Aftersales - Complaint View -  Fixed the issue with Download tab total count not displaying correctly",
        "Condition Report - Table of contents - Force Node to be a paragraph, if the user has added only an image to a Heading section",
        "Sage Importer - Set to run for 12 hours a day, not 24 and only Monday to Friday. So not processing out of hours",
        "Directory - Customers - Fixed issue with customer note list not pulling through the author field",
        "Directory - Customers - Reformatted the customer note list to be more like the Complaint Corrective action table, with times added to dates and column formatting",
        "Sage Importer - Fixed issue with the scenario, that a sale goes straight from Prospect to Order without going through the Quote process, meaning it has no quote status.",
        "Condition Report - Fixed issue where PDF preview would not generate without a cover tag.",
        "Condition Report - Fixed issue with where some images were missing from the PDF",
        "Aftersales - Complaint View - Documents Tab - table sorting not working",
        "Directory - Customers - Notes Tab - table sorting not working",
        "Contacts - List - Add styling to fixed date components in contacts",
        "Tasks - The cancel button click doesn't seem to enable the back function",
        "Aftersales - Complaint List - Table column widths need tweaking",
        "Aftersales - Complaint  - Fixed issue with InitialComplaint List is Wrong",
        "Condition Report - Reworked the create PDF to remove the static toplevel section, if it's subsections don't contain any html",
        "Aftersales - Complaint View -  Fixed the issue with edit complaint save not working",
        "Work - Master Project - Fixed issue with add new master can't display in list",
        "General - Made the majority of font sizes one size bigger and styling fixes from the consequences",
        "Complaints - Installation Company fixes, now shows the installation company name not the project customer's name",
        "Condition Report - Added download button to backup currrent PDF html text, when an error occurs",
        "Set password link - Fix for redirecting directly to the Login screen",
        "Aftersales - Complaints - List - Added label for Order value input",
        "Table - General - Fixed issue where table always show the footer, even if there is no data",
        "Condition Report - List - Report Date time component removed since it is not in the original json file.",
        "Customer - Contractor - Added referral checkbox for contractors",
        "Dashboard - Task - Task List - Filter dropdowns are lost and resets to default",
        "Admin - Fixed issue if a user is locked out, he can still login",
        "Customer - Contact popup Styling",
        "Condition Report - Removed blank project address rows from the 'Your project' table",
        "Condition Report - Removed image gap, since not used, and changed the default image width to 350 in exisiting templates",
    ]),
    createVersion("0.0.26", "2024-04-15", [
        "PDF - Generation - Created a new Docker image to run the PDF generation in. This should help with the timeout issue we were having with the PDF generation.",
        "Condition Report - Image Upload - will now resize massive image files to be more appropriate for a web applicaton. They will still be bigger than an A4 page size",
        "Contact- Add two new fields Plygene CPD and Coatings CPD",
        "Condition Report - Upload JSON files will resize massive image files to be more appropriate for a web applicaton. They will still be bigger than an A4 page size",
        "Reports - Added Project and Customer report",
        "Reports - Added Project Master and Project Quote report",
        "Reports - Added Project Document Categories report",
        "Reports - Added Project Sage Contractor report",
        "Reports - Added Project Communication report",
        "Reports - Added Project Complaint report",
        "Reports - Added Customer Contact report",
        "Work - Project Quote - Routing Broken on edit",
        "Tasks - Task field not needed in task view",
        "Aftersales - Reports - Complaint Status filter add",
        "Sage Importer - Changes required for the new test and live APIs",
        "General fixes, code cleanup, and bug fixes",
        "Updated third party server components to latest versions",
    ]),
    createVersion("0.0.25", "2024-03-30", [
        "Condtion Report - PDF - Added audit table to store what was upserted.",
        "Condition Report - Updating - No longer moves to the top of the page when saving",
        "Condition Report - Fixed loading screen, when document is loading, but does not show the loader when updating",
        "Condition Report - Refactored PDF Html saving out into file storage, due to file sizes limits slowing down the database and timing out",
        "Condition Report - Uploading images to file storage, instead of being embedded into the html",
        "Condition Report - Added visual cue when updating a document, to show that it is saving",
        "Condition Report - Template - Image functionality par to the pdf editor",
        "Condition Report - Fix for PDF download / view failing with new images changes",
        "Condition Report - Changed Update to Save",
        "TSM - List - Refactored and optimised.  Needs discussion",
        "Condition Report - Saving, looks for base64 images and converts them to files and saves to storage.  This improves performance when saving reports with embedded images",
        "Aftersales - Complaints - Date picker calendar appears much larger compared to other pages",
        "Aftersales - Complaints - List - Add pagination",
        "Customer - Edit - Fixed issue with TSM and BDM not updating when saving",
        "Task - Changed Start Date to Date Set",
        "Importer - V1 - Added import of emails from Sage",
        "Importer - V1 - Despatched data changes.",
        "Importer - Sage -  Added import of Sales orders from Sage. Need live data to test, test data only to 22/01/2024",
        "Task - When opening a task, the Assign this task to is pulling through the wrong field.",
        "Task - When assigning a task to complete / uncomplete the table does not refresh",
        "Select None & Select All filters - fixed filter dropdowns close the dropdown once they are selected.",
        "Reports - Added in infrastructure for reports and implemented Sage not found report, with count and reset functionality",
        "Task - The default selections for this dropdown should be Not completed",
        "Task - View Key word search",
        "Task - Completed field change not changing the list value",
        "Reports - Added Contractor Customers Plygene report",
        "Reports - Added Contractor Customer Coatings report",
        "DatePicker - Fixed sizing issue",
        "Complaints - Added pagination to the list",
        "Complaints - Fixed issue with duplicate preventative / corrective actions on import",
        "Complaints - Added new field for value",
        "Complaints - Fixed issue where editing would sometimes error due to missing data.",
        "Azure Function - Added Plygene virgin report",
        "Azure Function - Added Weekly Customer report",
    ]),
    createVersion("0.0.24", "2024-03-18", [
        "Added concept of Country to Database to bring in from Sage.  This will aid Big Change updating in the future",
        "General - Error messages checked and amended where needed, to be more informative",
        "TSM - List - List is now specific to the logged in TSM",
        "TSM - List - Dropdown list added for Admins, enabling them to view the lists of different TSMs",
        "Contacts - List - Row click now opens contact details modal, except the Customer column that navigates to that customer view",
        "Customers - New/Edit - Contact List - Row click now opens contact details modal",
        "TSM - List - Column widths optimized",
        "Azure Function - Ported across the Plygene virgin email processor and added the required database table for it and the Sage importer",
        "Azure Function - Ported across the the new customer weekly email.  And removed all the fields no longer applicable",
        "Azure Function - Implemented a get countries for Sage, so we can use a country drop down instead of free text for addresses and it will help with getting Big Change phone numbers correct.",
        "Azure Function - Added Repositories and structure for all the Azure functions to use common code, now all Azure functions live in the same project and not seperate projects",
        "Azure Function - Added some work on the Sage importer, whilst waiting for the API to be updated",
        "Importer - User - If user exists it no longer overwrites their password to their V1 password",
        "Contacts - View Contact Modal - Ticks added if contract is Plygene/coatings trained, but no date has been selected",
        "Importer - Importing project customer despatch data table for Plygene virgin email processor",
        "Condition Report - Fixed issue where files would file to upload if they failed to include a device object",
        "Project Quote - Status dropdown styling change",
        "General - Navigation/Back button changes. Navigation history is now stored and back button funtionality is improved",
        "TSM - List View - Despatch date column data coming through",
        "General - Table column heading sort direction arrows moved to the right of the heading",
        "General - Document uploader drag and drop functionality fixed",
        "Condition Report - Fixed issue where part of the address is not removed if it isn't filled in (postcode 3)",
        "Condition Report - Edited the template so so user TODO fields are now in coloured and made names consistent",
        "Contacts - List - Add contact button and modal added",
        "Contacts - Add/Edit Modal - Document uploader added",
        "Customer - Reworked Customer concept to remove specific customer types, now all customers are just customers, this makes it easier to add new customer types (as long as they have the same options) as well as allowed sorting and Filtering of TSMs / BDMs on the customer list now the types that don't support those options have defaults.",
        "Customer Callout - Fixed issue with the customer callout not showing the correct training status",
        "General - Button links to properties and Google Maps made consistent throughtout the site and added where necessary",
        "Master Project - New/Add - Delete buttons reinstated to the PRO/Specified By tabs",
        "Properties - List - Sorting added to Surveyed and Site Visited columns.  Sorting tested and improved",
        "Properties - List - CSV Download - Multi/Master/Quote count columns added",
        "Properties - List - Unnecessary commas and double commas removed",
        "General - Dropdown filtering delay reduced to make more responsive",
        "Customer - List - Default sort order changed to Customer Name",
        "Project Quotes - List - Address column formatting improved",
        "Complaint - Fixed issue with the complaint not loading if no project customer",
        "Complaint - Fixed database issue with the complaint not loading when editing, due to SQL getting confused",
        "Condition Report - Added Update and show PDF buttons to the top level control buttons",
        "Condition Report - Cleaned up the top section of the edit page, to make the top section smaller, so less scrolling.  Cannot eliminate it totally without making the page not work on laptops. Removed text as requested",
        "Condition Report - Fixed issue when pasting from Word and Word uses \r instead of space, so we removed the dodgy \r.  replacing \r with a space and dealing with multiple spaces.",
        "Condition Report - Added paste option into context menu, even though browsers do not support it.  So it works now like Google Docs and warns you.  Up to the user to hack a solution to get paste to work",
    ]),
    createVersion("0.0.23", "2024-03-01", [
        "Admin - User List - Select All and None buttons added to the Roles dropdown ",
        "Admin - User List - Sorting by Role now working",
        "Admin - User List - Now displays users that have been Disabled",
        "General - Address Lookup - Validation tweaked to make Address Line 2 optional and Country mandatory",
        "Multi site project - View - Prevention from removing any Master Projects if there are only two currently added to that Multi Site Project",
        "Properties - New/Edit - Occupier info callout now shows occupier status",
        "Aftersales - Complaints - Edit - Regarding the order number not being displayed",
        "Importer - Fixed issue with location objects on Properties when they exist.",
        "Importer - Fixed a bunch of dodgy data issues with contacts (emails in First name etc)",
        "Condition Report - Added roof name to each roof section",
        "Debounce added to the table's multi-select dropdown",
        "Tasks - Add/Edit - Validation added to dropdown fields",
        "Complaints - View - Add/Edit Action modals restyled to allow longer descriptions and better formatting",
        "Customers - View - CSV download now includes project counts",
        "Customers - Add/Edit - Validation messages improved.  Now appearing on dropdown fields",
        "Customers - The Master Project has been deleted, but the count is still displayed",
        "Contact - Download CSV - add status to contacts csv",
        "TSM - Locked down TSM menu option / routes so you must be TSM or Admin to see them.",
        "Condition Report - Switched out the Contact from the original Word document to be existing BDMs.",
        "Condition Report - Reworked PDF to cope with BDM not having a phone number or email address and clear up the front page",
        "Property - View -  Adding a tab for Details,Linked multi site,Linked master,Linked project quotes",
        "TSM - List - Site Visited toggle implemented",
        "TSM - List - Show All/Visible/Hidden dropdown improved",
        "TSM - List - List items navigate to the Project Quote when clicked",
        "Condition Report - Added application setting to hide the rest of the CRM",
        "TSM - Reworked the backend to add in the lower limit and status check and speed up the counting of documents and notes",
        "TSM - List - Added Contractor filtering dropdown",
        "TSM - List - Table sorting improved, Contractors column added",
        "TSM - List - Clear filtering button added",
        "General - Keyword search & filtering - now persistent when changing between pages",
        "Importer - Complaint - Fix for Corrective action being copied into Preventative action slot",
        "Importer - Property Site Visit, implemented import of existing data",
        "TSM - Request Site Visit Modal - *Other* option added to Contact dropdown",
        "Complaints - Validation improved for add/edit view",
        "Multi site projects - View - List items navigate to Master Project or Property when clicked",
        "Properties - View - Counts added to linked tabs",
        "Customers - View - Counts added to linked tabs",
        "Admin - BSM List - Name now filters by last name",
        "Complaints - Fixed issue where a complaint fails to display, since the project has two order numbers",
        "Complaints - List - Table sorting modified so that complaints are in the list order when switching between each one",
        "General - Currency values formatted to two decimal points",
        "Admin - Add/Edit - Improved validation messages",
        "Admin - BDM - Add/Edit - Modified validation to allow users to be created/typed in as well as select from the list",
        "Customer - List - Status filtering changed to only show customers with an Active or Dissolved status by default",
        "Customer - List - CSV download sorting improvements",
        "TSM - SiteVisit - Added email sending",
        "Contacts - List - Green ticks added to Plygene trained and Coatings trained columns if trained, but there is no date (as per V1).  CSV download amended to match",
        "Customer - Add/Edit - County field no longer mandatory.  Validation now requires either an email or phone number, not both",
        "Customer - Add/Edit - Phone number validation updated to allow longer/multiple numbers as well as +, / and spaces",
        "Contact - Add/Edit - Phone number validation updated to allow longer/multiple numbers as well as +, / and spaces",
    ]),
    createVersion("0.0.22", "2024-02-16", [
        "Condition Report - PDF preview modal displaying PDF rather than rendering HTML",
        "Master Project - Add compilation of duplicate record display",
        "Master project - View in the Complaints tab, when downloaded, does not work",
        "Master project - Download CSV does not display NetValue, WonValue, OpenValue, LostValue",
        "Multi site project - View - Add Note throws error failed to add note",
        "Multi site project - View - Add Note in fileUpload not save",
        "Multi Site Project -List - Add filters for FM and Specified by and make placeholder changes for the search",
        "Multi site project - View - Add Note throws error failed to add note",
        "Multi site project - View - Add Note in file Upload not save",
        "Customer - Removed the city filter and added an email filter to the customers list",
        "Complaints - If Customer search is not displaying in the dropdown. In such cases, user can add one through the Add customer functionality.",
        "Condition Report - Front page image is now full screen and no number, at the expense that it takes way longer to generate the pdf",
        "General - Fixed issues with downloaded file names containt UTF8 information, due to changes in the content disposition header",
        "Condition Report - New front page image added and reworked the front page text",
        "Documents - Document previewer added to document lists for documents that have been uploaded",
        "Documents - Word document previews now available",
        "Emails - Create password & change password emails styled similar to V1",
        "Multi site Project - Fixed glitch when adding Master projects from the list",
        "Address lookup - validation error messages updated with clearer prompts",
        "Project quotes - Details Tab - Contractor information callout added to the quotes list",
        "General - Fixed, some dropdowns had a larger font size",
        "Customer - List - Empty cells replaced with hyphens where there is no data",
        "General - Links to Google Maps to view address location now use only the Postcode rather than Address Line 1 and Postcode due to Google changing how it handles addresses",
        "Importer - Import Single and Aftersales project imports and creating Master projects for them into UAT",
        "Importer - Import Master Project and their subs and where Masters have contractors, creating an additional sub project into UAT",
        "Importer - TSM - Import TSM Notes into UAT",
    ]),
    createVersion("0.0.21", "2024-02-02", [
        "Tasks - Add new Filter to see Completed Tasks",
        "Directory - Properties - New Property - Fixed issue where adding a occupier was failing and added its respective functionality",
        "TSM - Added TSM Role so we can lock down TSM only functions and pages",
        "Documents - Added to contact notes dont save",
        "Documents - Uploader now shows preview for supported file types",
        "Project Quote - View - Add Conversion Date to Project Quote List table",
        "Importer - Single and Aftersales project imports and creating Master projects for them.",
        "Importer - Master and sub project imports and creating. (Needs rewriting to cope with Master Project Contractors)",
        "Importer - Projects - Importing Task, Complaints, Notes, Documents and complaint actions and tasks. (Complaints need re-writing based on this weeks changes)",
        "Importer - TSM - Importing TSM notes",
        "Contact - Fixed issue where Saving and Continue erroring",
        "Contact - Fixed infinite loop issue on Save and Continue",
        "Condition Report - Fixed an issue with repeated sections getting the correct images but not text",
        "Condition Report - Colourised the text in the current template",
        "Condition Report - Fixed missing date and it's formatting",
        "Condition Report - Pulled through more data that was missing",
        "Condition Report - Added section heading on image sections, so when they are sequential, the change can be seen",
        "Condition Report - Fixed issue with extra blank lines in the text of the table of contents",
        "Condition Report - Fixed issue with blank page if the table of contents is more than a page long",
        "Condition Report - Removed the header from the PDF",
        "Condition Report - Image changes to stop images splitting across pages",
        "General - Turned off the auto populate /autocompletion on all textboxes",
        "Condition Report - Titles and Page break fix",
        "Complaints - Fixed issue with pre import complaints crashing when viewing, due to no category",
    ]),
    createVersion("0.0.20", "2024-01-19", [
        "Admin - Debounce added to Keyword Search for improved searching",
        "Admin - User List - item count or number of items incorrect",
        "Aftersales - Debounce added to Keyword Search for improved searching",
        "Aftersales - Complaints - Radio button label needs centering vertically",
        "Dashboard - Debounce added to Keyword Search for improved searching",
        "Dashboard - Task - Task List - Port from V1",
        "Dashboard - Task - Task list shows completed items",
        "Dashboard - Task - Task List - ViewModel should be a singleton",
        "Dashboard - Task - Task List - When first logged in, the task list displays the wrong data",
        "Dashboard - Tasks - Overdure Items not highlighted",
        "Dashboard - Tasks - Missing Overdue functionality visuals updated",
        "Dashboard - Task - Due Date & Time",
        "Directory - Debounce added to Keyword Search for improved searching",
        "Directory - Contacts - List - Keyword Search tested and improved",
        "General - List pagination fixes to ensure correct item totals and page numbers when searching and filtering",
        "General - Clear Filtering button added to all table lists where needed",
        "General - Implemented the ability to type in a page number in table pagination in all tables where needed",
        "General - Table pagination controls improved",
        "Work - Debounce added to Keyword Search for improved searching",
        "Work - Master & Project Quotes - View & Add/Edit - fixes to make default TSM & BDM values: N/A",
        "Work - Master & Project Quotes - List - All items now displaying when navigated from the side menu.  Item count now displaying correctly",
        "Work - Multi - Add/Edit - Improved debounce search for dropdowns",
        "Task - Edit - Missing Completed flag",
        "Complaints - view - x of y count added below next/prev buttons",
        "Contacts - Selection drop downs dont clear fix",
        "General - Updated Server to latest language code base",
        "General - Updated Server packages to latest security patches",
        "Project Quote - List - Fixed issue with CSV not downloading",
        "Importer - Started working on importing Single and Aftersales projects",
        "Condition Report - Stop PDF breaking in the middle of a table",
        "Condition Report - Table of contents changes ",
        "Tasks - Missing Completed Functionality",
        "TSM - List view created",
        "TSM - Notes Modal created.  Can add, edit and delete notes",
    ]),
    createVersion("0.0.19", "2024-01-08", [
        "Directory - Customer - Add  - Added new Facility Manager Type. Can add customers of that type",
        "Work - Tables - Added background colours",
        "Customer - List - Added filtering by Facility Manager",
        "Aftersales - Complaints - Added PDF download to individual complaints",
        "Aftersales - Complaints - List - Filtering section styled to resemble similar pages",
        "Aftersales - Complaints - View - Layout created including tab header",
        "Aftersales - Complaints - View - Details tab implemented",
        "Aftersales - Complaints - View - Next and Previous arrows implemented to navigate between complaints",
        "Aftersales - Complaints - View - Corrective Action Tab implemented (List, Add, Edit & Delete)",
        "Aftersales - Complaints - View - Preventative Action Tab implemented (List, Add, Edit & Delete)",
        "Aftersales - Complaints - View - Nav to Edit button implemented",
        "Aftersales - Complaints - Add / Edit - Styling tweaks to bring in line with similar pages",
        "Aftersales - Complaints - Edit - Ability to resolve a complaint implemented",
        "Aftersales - Complaints - Edit - Navigation of back button and when saving from complaints page implemented",
        "Aftersales - Complaints - Reports - Reworked the reports tab",
        "Aftersales - Complaints - Reworked the tab routing",
        "Work - Master Project - View - Complaints - Added PDF download to individual complaints",
        "Work - Project Quote - View - Complaints - Added PDF download to individual complaints",
        "Work - Multi Site Project - Refactored to maintain filter settings when navigating between pages",
        "Work - Multi Site Project - Fixed issue with saving on edit",
        "Work - Multi Site Project  - Complaints can only be added to master project if any of the project quotes statuses is greater than Order",
        "Work - Master Project - Refactored to maintain filter settings when navigating between pages",
        "Work - Project Quote  - Refactored to maintain filter settings when navigating between pages",
        "Work - Project Quote - Complaints can only be added to project quotes if the status is greater than Order",
        "Work - Project Quote - List View - Product filter now has clearer styling to show headers and items.  Count fixed",
        "Work - Project Quote & Master Project - View - Complaint tab table - resolved column now showing values",
        "Directory - Customer - List - Added status to the table",
        "Directory - Customer - Refactored to maintain filter settings when navigating between pages",
        "Aftersales - Complaints - Report - Implemented complaint with category CSV download",
        "Aftersales - Complaints - Report - Implemented complaints between dates PDF download",
        "Aftersales - Complaints - Refactored to maintain filter settings when navigating between pages",
        "Directory - Customer - View - Details - Added latest dates that a contact has been Plygene or Coatings trained",
        "Directory - Customer - View - Linked Multi site projects, implemented table",
        "Directory - Customer - View - Linked master projects, implemented table",
        "Directory - Contacts - Refactored to maintain filter settings when navigating between pages",
        "Directory - Properties - Refactored to maintain filter settings when navigating between pages",
        "Logout - Added 'Are you sure you want to log out?' modal",
        "Condition Report - Re-added the Table of contents on download.",
        "Multi Select boxes - Fixed issue where the dropdown would not close when clicking outside of the box",
        "Admin - Users - Refactored to maintain filter settings when navigating between pages and fixed the search box to search after three characters",
        "Admin - BDM - Refactored to maintain filter settings when navigating between pages and fixed the search box to search after three characters",
        "Admin - TSM - Refactored to maintain filter settings when navigating between pages and fixed the search box to search after three characters",
        "Admin - Complaint Assignee - Refactored to maintain filter settings when navigating between pages and fixed the search box to search after three characters",
        "Admin - Complaint Assignee - Imported Complaint Assignees from V1 database",
        "Admin - Complaint Assignee - Added open complaint count to the list and implemented sorting on the column",
        "Aftersales - Complaints - Implemented preventative and correction action tabs",
        "Aftersales - Complaint - Fixed issue with master project complaint view not loading",
        "Aftersales - Complaint - Implemented the Document tab.",
        "Aftersales - Complaint - Added counts to the tabs and wired them in to the database and tab actions",
        "Aftersales - Complaint - Fixed issue where date button doesn't set the state radio button",
        "Directory - Customer - View - Tasks - Wire in the Task list in the Customer View",
        "Work - Master Project - View - Tasks - Wire in Task list in Master Project",
        "Work - Project Quote  - View - Tasks - Wire In Task Lists on Project Quote",
        "Admin - Go through Admin section and add additional validation to the models",
        "Directory - Go through all models and ensure validation matches the database",
        "Work - Go through models and ensure validation matches the database",
        "Aftersales Complaints - Ensure validation matches expected database validation",
        "Add Task Screen",
        "General - Table styling improved to better reflect mocks throughout site",
        "General - Multi Select Dropdown now accepts sub menu items",
        "Condition Report - Missing date, partial fix, added date but needs formatting to UK date and not the date in the Json file",
    ]),
    createVersion("0.0.18", "2023-12-11", [
        "Condition Report - Fixed issue causing file to fail with timezone issue.",
        "Condition Report - Template - Can now add the additional comment sections that are missing from the 'temp file.json' but are in the other files",
        "Condition Report - PDF - Put CSS change in to stop tables from being cut off by page breaks",
        "Master Project - Add / Edit - Fixed callouts for the 'Add / Edit' page",
        "Aftersales - List - Started mapping out the complaints list and report tabs, based on V1",
        "Customer - Add New - Fix for json issue with having the created by field not filled in correctly",
        "Multi Site Project - List - Implemented list",
        "Multi Site Project - List - Implemented CSV download",
        "Multi Site Project - Add / Edit - Implemented add / edit",
        "Multi site project - Add - Save & Continue flow implemented",
        "Multi Site Project - View - Implemented view outline",
        "Multi Site Project - View - Implemented view tabs",
        "Multi Site Project - View - Implemented value totals for Master Project table",
        "Multi Site Project & Master Project - fixes and improvements to handling notes & documents",
        "Multi site project - Delete function actions added",
        "Work - Information callouts added where neccesary",
        "Admin - TSM / BDM / Complaint Assignee - Fixed date issues",
        "Complaints - List - Implemented complaints list",
        "TSM - Fixed some issues with blank names.  Needs more work.",
        "Aftersales - Complaints - Add / Edit - Partial implementation. Can add without actions. ",
        "Master Project - View - Complaints - Wired in the complaint view with limited functionality",
        "Project Quote - View - Complaints - Wired in the complaint view with limited functionality",
        "Customers - List - Filtering now multiselect",
        "Side bar menu - Highlighting of current page being viewed to aid navigation",
        "Contacts - List - Filtering now multiselect",
        "Properties - List - Filtering now multiselect",
    ]),
    createVersion("0.0.17", "2023-11-27", [
        "Project Quote - List - Implemented sorting on the table",
        "Project Quote - View - When clicking through to a customer,  return button changed to return to the Project Quote",
        "Customer - Edit - Fixed back routing not going back to the list, but the edited customer",
        "Master Project - Add - Added communication section after save and continue",
        "Master Project - Add - Added document section after save and continue",
        "Master Project - Add / Edit Can now add / remove PRO (occupiers)",
        "Master Project - Add / Edit - Pro add and delete occupiers with counts",
        "Master Project - Add / Edit - Added occupier call out on search",
        "Master Project - Add / Edit - Specified by add and delete specifiers with counts",
        "Master Project - Add / Edit - Added specifier call out on search",
        "Master Project - View - Pro add and delete occupiers with counts",
        "Master Project - View - Specified by add and delete specifiers with counts",
        "Master Project - Edit - Added are you sure to changing property, since that seems quite dangerous and we don't want to accidentally change the property",
        "Project Quote - Add / Edit - Added contractor call out on search",
        "Project Quote - Calculate status and values needed for Master / Multisite projects",
        "Project Quote - Details - Added link to Master Project on the details tab",
        "Master Project - Add / Edit - Added property call out on search",
        "Refactored created dates",
        "Contact - Download CSV - Fixed issue with Contact name",
        "Contact - Download CSV - Sorted filtering.",
        "ComplaintAssignee - Fixed issue with class",
        "Customer - Fixed issue with saving notes not working",
        "Customer - Note - Added document download option",
        "Customer - Document - Ensure the document downloads",
        "Condition Report - Template changes to front page.",
        "Condition Report - Added missing representative.",
        "Condition Report - Removed full stop from end of Your Project",
        "Condition Report - Auto moves images after delete.  Note, might need two deletes to achieve this.  One to delete the image and one to delete the div.",
        "Condition Report - Rooflight condition text - pulling in wrong text fixed",
        "Multisite Project - List - Implemented Status filter",
        "Master Project - List - Implemented Status filter",
        "Master Project - View - Implemented Status filter and manual update of status",
        "Multi Site Project - Add / Edit created routing and blank page ready for implementing",
        "Condiditon Report - Merged Executive summary and conclusion sections",
        "Project Quote - Add / Edit - Added info call out to selected contractor",
        "Master Project - Added info call outs to selected occupiers and specifierts",
        "Master Project - Back end generated data calculations",
        "Multi Site Project - Back end generated data calculations",
        "Project Quote - On manual project change, now re-calculates values and those of its parent and parent's parents",
        "Master Project - Details - Wired in Quote status and whether we can delete or not",
        "Master Project - Details - Wired in delete project quote button, if appropriate",
        "Master Project - Details - Added link on details to multisite project when that is available",
    ]),
    createVersion("0.0.16", "2023-11-10", [
        "Importer - Created an importer program to copy data from the V1 database to the V2 database",
        "Importer - Added importing users and user roles",
        "Importer - Added importing of Bdms",
        "Importer - Added importing of Tsms",
        "Admin - Users - Replaced user role drop down with the new one",
        "Admim - Edit TSM - Fixed issue with selected user not turning up in dropdown",
        "Admin - Edit BDM - Fixed user Bdm not turning up in the dropdown. Still need to fix non user Bdms being added to the dropdown",
        "Admin - Edit Complaint Assignee - Fixed user not turning up in the dropdown",
        "Auditing - Extended data fields collected",
        "Importer - Added importing of Contractors",
        "Importer - Added importing of Contractor Contacts",
        "Importer - Added importing of Contractor Communications",
        "Importer - Added importing of Contractor Documents",
        "Importer - Added importing of Specifiers",
        "Importer - Added importing of Specifier Contacts",
        "Importer - Added importing of Specifier Communications",
        "Importer - Added importing of Specifier Documents",
        "Importer - Added importing of Occupiers",
        "Importer - Added importing of Occupier Contacts",
        "Tables - Default page size to 100 on all tables",
        "Project Quote - Upsert / Delete - Added auditing",
        "Project Quote - Contractor - Add / Delete - Added auditing",
        "Project Quote - Note - Upsert / Delete and document Add / Delete audit added",
        "Contact - List - Item clicks through to customer view.",
        "Condiditon Report - Added Default Missing Image placeholder for json files incorrectly exported.",
        "Admin - Edit BDM - Fixed issue with selected user not turning up in dropdown",
        "Importer - Added importing of Properties, from the manual import previously used.  Should better cope with the Ireland data and move county data to city, when no city data",
        "Contact - Add / Edit - Added Marketing Consent and wired in ready for when I add the V1 changes, so the marketing consent will come in from the V1 database",
        "Contacts - Added Marketing Consent to mimic what also went into V1, so we can change the importer to bring the values across.",
        "Condition Report - Rewrote file upload to cope with files bigger than 20Mb.  This will need doing for every other file upload.",
        "Master Project - Property Callout box implemented",
        "Master Project - Upsert / Delete - Added auditing",
        "Master Project - Specified by / Pro - Add / Delete - Added auditing",
        "Add Document Modal - Fixed issue with dropdown list being inside the modal box",
        "Customer - Contact - Tab count should now change with add / edit contact",
        "Projects - Generic Scrolling search box, should now change colour based on project type",
        "Property - Fixed remove occupier and stopped list allowing adding the same occupier more than once",
    ]),
    createVersion("0.0.15", "2023-10-27", [
        "Condition Report - PDF - Edit. Stripping out attributes from pasted in text, so we can allow copy and paste functionality again",
        "Condition Report - PDF - Edit. Stripping out additional img attributes from pasted in text for internal image copy / cut / paste",
        "General - Audit Trail functionality implemented.  Needs wiring in to each function that we want to audit",
        "Master Project - Add master project functionality",
        "Multi-site Project - Details - Document view partially implemented for when we need it",
        "Multi-site Project - Details - Communication view partially implemented for when we need it",
        "Multi-site Project - Work on backend functionality ready for it's client implementation",
        "Master Project - View - Header and Details tab implemented",
        "Master Project - Add / Edit - Property Tab implemented",
        "Master Project - View - Documents and document list implemented",
        "Master Project - View - Communication notes and note list implemented",
        "Master Project - Add / Edit Project Quote implemented",
        "Admin - Added audit functionality to the upsert and delete admin operations",
        "Project Quote - View - Task and Complaint tabs started awaiting Task and complaint functionality",
        "Project Quote - View - Details tab implemented",
        "Project Quote - View - Communication tab implemented",
        "Project Quote - View - Document tab implemented",
        "Project Quote  - View - Header and Details tab status change partially implemented",
        "Note: Status calculation algorithms for Project Quote and how they relate to Master Projects have not yet been implemented, since we need Sage Data and clarification on how it works",
        "Project Quote - Task and Complaint tabs started awaiting Task and complaint functionality",
        "Property - Added audit functionality to the upsert and delete property and property ocuppier operations",
        "Customer - General refactor and optimisation, so we could re-use the concepts for documents and communication tabs for projects",
        "General - Refactor of some local classes and enumerations out into generic code areas and replaced some concrete implementations with interface equivilents",
        "Customer - View - Notes - Wired in the tab count to the adding / deleting of notes",
        "Customer - View - Document - Wired in the tab count to the adding / deleting of documents",
        "Contacts - Added audit functionality to add / edit and delete contact",
    ]),
    createVersion("0.0.14", "2023-10-13", [
        "Customer Details - Implemented change state",
        "Project Quote - Implemented the header section (minus status change)",
        "Project Quote - Started the Communication tab.",
        "Project Quote - Implemented the Details tab and wired in the Project quote contractor table and implmented local sorting utilities",
        "Database - Added Project Quote / contractor functionality",
        "Database - Added Task concept and tables for task types and task sub types.",
        "Select - Created selection boxes to cope with project status changes and customer status changes",
        "General fixes and optimisations",
        "Master Project - Implemented get related for new and existing projects",
        "Master Project - Implemented add master project",
        "Projects - Created control for lists for add / edit property / pro / specified",
        "Master Project - Implemented add master project tabs for edit property / pro / specified",
        "Customer Details - Edit Customer Details Button not working fix",
        "Condition Report Template - Added Contact name and details onto the front cover",
        "Customer List - Removed the spinner modal when typing into the search, to fix the issue of page flashing and being reset",
        "Condition Report Template - Added in the missing QU__Postcode_3_Answer field to the front sheet. This is where the postcode is mostly stored.  The user will still need to manually remove fields that might be there but are not.",
        "Customer View - Document Tab - Fixed issue with downloading files.   Currently not all types for download are supported. ",
        "Customer - Add Edit - Fixed issue with contractors not requiring BDM and TSM, so no setting them would fail to load the contractor customer",
        "Customer - Fixed issue where genericising the note functionality (to use with project quotes and master project quotes), broke the customer notes",
    ]),
    createVersion("0.0.13", "2023-10-09", [
        "Projects - General - Added Project Statuses to the system",
        "General - Created a new Multi select box to cope with option colours and checkboxes and fix the display of the counts",
        "General - New multi select box. Added ALL and None buttons and stopped the dropdown from closing when an item is selected. Click on the main control to close.",
        "Timescale - Added some timescales and implemented on master project list",
        "Admin - User replaced role select box with the new one",
        "Property - New / Edit - layout refined",
        "Property - New / Edit - Create Property function implemented",
        "Property - New / Edit - Edit Property function implemented",
        "Property - New / Edit - ability to add occupiers to a property added",
        "Property - View - layout created including tables",
        "Property - View - Leaflet map added to show coordinates of property",
        "Multi Site Project View - Added blank details tabs and routing",
        "Master Project View - Added blank details tabs and routing",
        "Project Quote View - Added blank details tabs and routing",
        "Master Project List - Download CSV",
        "Master Project List - Added Filtering dropdowns",
    ]),
    createVersion("0.0.12", "2023-09-29", [
        "Master Project - Backend tables added and server side structure and CRUD functionality.",
        "Project Quote - Backend tables and server side structure and some CRUD functionality",
        "Master Project - Implemented deletion of a master project",
        "ToBeConfirmed renamed to Directory",
        "Contact - list view created",
        "Contact - list view filtering, searching & sorting implemented",
        "Contact - list view CSV download implemented",
        "Property - routing created",
        "Property - Backend tables and imported locally properties from live",
        "Property - list view created",
        "Property - list view filtering, searching & sorting implemented",
        "Property - list view CSV download implemented",
        "Property - New Property view created.",
        "Scrollable List With Search component created (and added to New Property view)",
        "Master Project - Add / Edit - Started the page layout",
    ]),
    createVersion("0.0.11", "2023-09-22", [
        "Condition Report PDF - Added table of contents implementation.",
        "Customer Contact - Document functionality added",
        "Condition Report - Backend - Optimised cleaning up of repeated sections when they are deleted",
        "Condition Report PDF - Removed pop up about Table of contents plugin missing, now we have written our own",
        "Condition Report PDF - Added project name and site visit date into the pdf download filename",
        "Condition Report PDF - Added searching / sorting and paging to the Pdf table",
        "Condition Report PDF - Refactored the delete pdf data option to update the table",
        "Reset Password - Fixed configuration file for UAT",
        "Customer - List - Implemented the Download CSV functionality.",
        "Customer - Notes - Attachments implemented",
        "Customer - New - Design updated for added notes as per mocks for projects",
        "Customer - View - Contacts modal when row is clicked on shows contact details plus any notes and documents attached",
        "Customer - Save & Continue added to notes and contact modals so a note or contract can be created and then an attachment can be added",
    ]),
    createVersion("0.0.10", "2023-09-15", [
        "Condition Report PDF - Added mechanism to add the contact information to the final PDF",
        "Condition Report PDF - Fix for top level sections that are button groups or multiselect having all options displayed instead of the chosen option",
        "Condition Report Template - Added placeholders for the unchecked and checked check boxes",
        "Condition Report Template - Fixed an issue where sometimes the RHS is missing when selecting a repeated section",
        "Condition Report Template - Fixed issed where adding a new section from inside a repeated section, does not clear the textbox area",
        "Condition Report Template - Added placeholders for table of contents and page breaks before and after",
        "Condition Report PDF - Added placeholder functionality into the editor with a limited set of placeholders",
        "Customer - View - Sorting implemented in Documents, Notes & Contact tables",
        "Customer - View - Modal added to Contact tab when contact is clicked in the list",
        "Customer - New/Edit - Type dropdown box disabled when editing a customer so type cannot be changed once a customer is created",
        "Customer - List - Sorting, searching and filtering implemented",
        "Customer - View - Add, Delete and Edit actions added to Note list",
        "Customer - View - Add and Delete actions added to Documents list",
        "Customer - View - Add, Edit & Delete actions added to Contact list",
        "Customer - View - Primary Contact flag added to Contact list to indicate a primary contact.  Upsert edited to only allow one primary contact per customer",
        "Condition Report PDF - Added footer back in, but that breaks the front page, being 100% image.  Added PDF margins, which also breaks it.",
        "Condition Report PDF - Added header. Backend changes to cope with this new information that needs storing with the PDF data",
    ]),
    createVersion("0.0.9", "2023-09-08", [
        "Customer - View - View created",
        "Customer - View - Routing implemented",
        "Customer - View - Customer View separated into different tabbed sections, stored procedures created to fetch relevant data in each tab",
        "Customer - View - Details, Notes, Documents & Contacts tab views implemented, including modals for adding new Notes and Contacts.  Add Document modal to be implemented",
        "Customer - Edit - Edit Customer routing implemented",
        "Customer - Edit - Edit Customer view & stored procedure implemented",
        "Customer - New/Edit - Customer Website input field added",
        "Customer - List - Sorting and filtering partially implemented, to be finished in the next sprint",
        "Customer - List - Row click navigates to Customer View",
    ]),
    createVersion("0.0.8", "2023-09-01", [
        "Condition Report PDF Data - Fixed issues with missing images, and missing image sections.",
        "Condition Report List - Implemented search on the table",
        "Condition Report PDF Data - Fixed issue where saving the PDF data might not refresh the page on success",
        "Condition Report Template - Added site visit date placeholder (since not in the pages) and wired into the pdf data generator",
        "Condition Report Template - Fixed issue with default css generation failing to save the file into storage",
        "Condition Report List - Implemented delete condition report",
        "Condition Report List - Implemented sorting on the table",
        "Condition Report PDF - CSS fixes for agent defaults breaking the tables and borders",
        "Condition Report List - Added a modal to view the PDF html instead of having to download it",
        "Condition Report PDF - Added the view PDF modal here too.",
        "Condition Report PDF - Fixes for strings with ampersands in when checking template json verses condition report json",
        "Condition Report PDF - Implemented Button group and select when used in a top level flow section",
        "Condition Report upload - Fail files with missing image byte data ",
        "Condition Report PDF - Check for cover class being added and then adding a main body class so we can have margins in the document but not on the cover page.  Assumes the cover page is the first section in the document. (not backward compatible)",
        "Condition Report CSS - Updated all UAT CSS files to the latest standard",
        "Customer Contact - Back end stored procedures and server side code to add a new contact to a customer",
        "Condition Report PDF Viewer - Fixed issue with having three overflow sections",
        "Contacts - Implmented Contact Status",
    ]),
    createVersion("0.0.7", "2023-08-25", [
        "Condition Report - Images now split into rows, two to a row with gap inbetween.",
        "Condition Report Template - Created base CSS file from example Word document",
        "Condtion Report Template- Create a copy of the base CSS file and store when a new template is created",
        "Condition Report Template - Add / Edit - All section text boxes now use the CSS file and set up the editor appropriately",
        "Condition Report - PDF Editor, now uses the css file in the editor for the template it is based off.",
        "Conditon Report PDF - PDF Generator for download uses the templates' css file with some overrides for print.",
        "Condition Report Template - Fixed issue where dropdown could be chosen as a section, which left an empty section",
        "Condition Report Template - Add Flow section, fixed issue seen last demo, where the table is not generated for flow sections",
        "Condition Report PDF Data - Fixed issue where css not generated for new Pdf data, so don't get the editor",
    ]),
    createVersion("0.0.6", "2023-08-18", [
        "Customer - Add - Added customer Notes",
        "Customer - Add - Add / remove customer documents",
        "Customer - Add - Start of adding contacts",
        "Condition Report Template - Started work on CSS generation and how PDF will interact with it",
        "Condition Report Template - List sorting and searching",
        "Condition Report Template - Impelmented deleting of templates. Can still use old docs using those, but not use for new documents  ",
        "Condition Report - Delete generated PDF data implemented",
        "General - Refactored the endpoints and repositories",
    ]),
    createVersion("0.0.5", "2023-08-11", [
        "Condition Report Template- Refactored to move the repeated sections into a new page, so they can be sorted independently of the main list, which has had these remvoved.",
        "Condition Report Template - First attempt at auto adding tables for each multi select and button group set of options.",
        "Condidion report Template - Added image with (with defaults) for Sketch and Camera type sections",
        "Tables - Refactored tables to fix html errors",
        "Condition Report - Template - Refactored Add json file into its own component",
        "General - Fixed some styling and spelling issues",
        "General - Created a generic download button for tables",
        "Condition Report Template - Auto saving of repeated sections on page leave and section change",
        "Address Component - refactored the validation into its own model",
        "Customer - Specifiers and Occupier types are now saved",
        "Customer - Validation refined. Visible fields changed depending on Customer type",
        "Customer - Add Customer button changed to Save & Exit and Save & Continue button added and implemented.",
        "Customer - Hidden add note / document / contact on Customer Add, until saved, when it displays",
        "Generic Modal - Added to speed up modal creation in the future and maintain code consistency",
        "Add / Edit Contact Modal - Created with partial functionality",
    ]),
    createVersion("0.0.4", "2023-08-04", [
        "Condition Report- First pass at repeatable sections in the template side.",
        "Condition Report - Put in the mechanisms to download the generated PDF. At the moment, the PDF is regenerated on the fly.  We need to save this and download the saved version and have an option to regenerate. Need Audit trail functionality to be implemented to do this properly",
        "Condition Report - Converting Condition report json file into PDF data from the template, now copes with repeated data.",
        "Condition Report - Converting Condition report json file into PDF data from the template, now adds sketches and images (needs style guidleines added next week).",
        "Condition Report - Converting Condition report json file into PDF data from the template, initial coping with multiselect options",
        "Condition Report - Converting Condition report json file into PDF data from the template, initial coping with button group options",
        "Condition Report - Template - Added additional table options to the context menu",
        "Condition Report - Edit PDF - Added additional image options to the context menu and temporary css to add some space to the images",
    ]),
    createVersion("0.0.3", "2023-07-28", [
        "Condition Report Template - Added Template number mechanism",
        "Project Quote - Added Project number mechanism",
        "Condition Report Template - Added start of backend for PDF generation",
        "Condition Report Template - Added section mechanism to cope with multi-select",
        "Condition Report Template - Sdded section mechanism to cope with button groups",
        "Condition Report Template - Implmented drag and drop sections",
        "Condition Report Template - Implemented concurrency mechanism (backend)",
        "Condition Report Template - Implemented placeholder in the text are to be specific to the section being edited",
        "Condition Report - Reworked the backend classes to generate type key places to match the template source type keys and generate on file import",
        "Condition Report - Generate data needed to show in template table, once we have merged the report to the template (needs table sorting adding)",
        "Section Report Template - Implemented Section types, so we can later cope with repeated sections, (needs client side support)",
        "General - Fixed issues with modals not closing from the header",
        "Condition Report - Getting eligible template list so we can choose which template to load to start the pdf process",
        "Condition Report PDF - Generating a simple version of the html needed to create the PDF.  Proof of concept.",
        "Condition Report PDF - Added list of PDF data records for the condition report and a way to view the data.",
    ]),
    createVersion("0.0.2", "2023-07-21", [
        "Side Bar - Made sidebar open by default and not close on selection.  Can manually close if needs be",
        "Admin - Added TSM List and Add / Edit TSM",
        "Admin - Added initial Complaint assigneed List and add / edit, still needs complaint count when we add complaints",
        "Admin - Added intial BDM List and Add / Edit BDM",
        "Condition Report - Back end tables and creating text placeholders and initial blank template, based on the inputted file.  At the moment can add the file, nothing returned",
        "Condition Report - Initial section Add for paragraph type and added placeholder plugin to the main textbox",
        "Cutomer - Initial customer list implemented",
        "Customer - Can add a contractor, validation to be refined",
        "Address Box - Started working on a generic Address search box and manual entry for use throughout the site,",
    ]),
    createVersion("0.0.1", "2023-07-07", [
        "Sprint 1 Release. - User setup and login, change password - Need email templates",
        "Condition Report - Import json file and view answered questions",
        "Initial table design, with paging, sorting, search and filtering done server side",
        "Initial routing",
        "Major page headers and stub pages created and stub table added to list pages",
        "Customer Add page started, to determine control styling",
        "Added an error modal",
        "Admin - Added stubs for TSM, BDM and complaint assignee",
        "Created default layouts and sidebar and headers",
        "Created page footer with changelog",
        "Created beginning theme and button choices",
    ]), */
];

const currentVersion = versions
    .filter((version) => version.items && version.items.length > 0)
    .reduce((previous, current) => {
        return Utils.versionNumbers(previous.version, current.version) > 0 ? previous : current;
    }).version;

export const ChangeLog: { currentVersion: string; versions: ChangeLogVersion[] } = {
    currentVersion,
    versions,
};
